import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import TimeMexJp from "../TimeMexJp"
import SearchHeader from "../SearchHeader"
import logoMexico from "../../images/919_mexico_logo.webp"
import arrowRight from "../../images/home/arrow-right.svg"
import dot from "../../images/new-header/dot.svg"
import usFlag from "../../images/new-header/usa-flag.svg"
import chinaFlag from "../../images/new-header/china-flag.svg"
import lanSwitcher from "../../images/new-header/lan-switcher.svg"

const Header = () => {
  // Images header Mexico

  //Menu state
  const [hamburgerMenuState, setHamburgerMenuState] = useState("")

  //Click outside menu
  const menuRef = useRef(null)
  const hamburgerButton = useRef(null)

  const handleClickOutsideMenu = event => {
    if (
      !hamburgerButton.current.contains(event.target) &&
      !menuRef.current.contains(event.target)
    ) {
      setHamburgerMenuState("")
      //setIsVisible(false)
    }
  }

  //Menu state
  const [isVisible, setIsVisible] = useState(false)
  //Click outside menu
  const switcherRef = useRef(null)
  const switcherButton = useRef(null)
  const handleClickOutsideSwitcher = event => {
    if (
      !switcherRef.current.contains(event.target) &&
      !switcherButton.current.contains(event.target)
    ) {
      setIsVisible(false)
    }
  }

  const toggleDiv = () => {
    setIsVisible(prev => !prev)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideMenu)
    document.addEventListener("mousedown", handleClickOutsideSwitcher)
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu)
      document.addEventListener("mousedown", handleClickOutsideSwitcher)
    }
  }, [])

  const menuSec1 = [
    { name: "お仕事紹介の流れ", link: "/process" },
    { name: "よくある質問", link: "/about-mexico" },
    {
      name: "今週のピックアップ求人",
      link: "/category/オススメのメキシコ求人",
    },
    { name: "求人情報 一覧", link: "/jobs-listing" },
    { name: "無料相談フォーム", link: "/job-form" },
  ]
  const menuTopFive = [
    {
      name: "1位 メキシコで働くための12ヵ条",
      link: "/info/12-article-for-work-in-mexico/",
    },
    {
      name: "2位 職種別、メキシコと日本の違い",
      link: "/info/different-points-by-occupation/",
    },
    { name: "3位 メキシコの福利厚生", link: "/info/employee-benefits/" },
    { name: "4位 オンライン面接を完全攻略！", link: "/info/online-interview/" },
    {
      name: "5位 スペイン語での履歴書の書き方",
      link: "/info/how-to-write-a-cv-in-mexico/",
    },
  ]
  const menuSec2 = [
    { name: "メキシコで働く", link: "/info-cat/mexico-jobs" },
    { name: "スペイン語を学ぶ", link: "/info-cat/spanish" },
    { name: "メキシコで暮らす", link: "/info-cat/mexico-life" },
    { name: "クイックからのお知らせ", link: "/info-cat/company-information" },
  ]
  const menuSec3 = [
    { name: "企業のご担当者様へ", link: "/employers" },
    { name: "会社概要", link: "/about" },
    { name: "お問い合わせ", link: "/contact-us" },
  ]

  return (
    <>
      <div className="header-style">
        <Link to="/" className="mexico-logo">
          <img src={logoMexico} alt="919 Mexico" width="380" height="40" />
          <p>メキシコ求人情報とメキシコ就職・転職サポート</p>
        </Link>
        <div className="header-right">
          <Link to="/jobs-listing" className="no-show-mobile">
            <button className="header-right_b2">求人情報</button>
          </Link>
          <div className="search-box no-show-mobile">
            <SearchHeader />
          </div>
          <div className="lang-switcher">
            <div
              className="u-flex u-align-center"
              onClick={toggleDiv}
              ref={switcherButton}
            >
              <img src={lanSwitcher} alt="language switcher" />
            </div>
            <div
              className={"lang-dropdown " + `${isVisible ? "show" : ""}`}
              ref={switcherRef}
            >
              <ul>
                <li className="mb-05">
                  <a href="/en">EN</a>
                </li>
                <li className="mb-05">
                  <a href="/es">ES</a>
                </li>
                <li>
                  <a
                    href="https://919mexico-ch.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CN
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div
            className={"hamburger-menu " + hamburgerMenuState}
            onClick={() => {
              hamburgerMenuState === ""
                ? setHamburgerMenuState("active")
                : setHamburgerMenuState("")
            }}
            role="button"
            tabIndex="0"
            onKeyDown={e => {
              if (e.key === "Enter" || e.key === " ") {
                hamburgerMenuState === ""
                  ? setHamburgerMenuState("active")
                  : setHamburgerMenuState("")
              }
            }}
            ref={hamburgerButton}
            aria-label="Hamburger menu Japanese"
          >
            <svg
              id="hamburger"
              width="60"
              height="40"
              className="no-show-mobile"
            >
              <g
                stroke="#000"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path id="top-line" d="M10,10 L45,10 Z"></path>
                <path id="middle-line" d="M10,20 L45,20 Z"></path>
                <path id="bottom-line" d="M10,30 L45,30 Z"></path>
              </g>
            </svg>
            <svg id="hamburger" width="40" height="35" className="show-mobile">
              <g
                stroke="#000"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path id="top-line" d="M10,10 L35,10 Z"></path>
                <path id="middle-line" d="M10,17 L35,17 Z"></path>
                <path id="bottom-line" d="M10,24 L35,24 Z"></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div className={"header-dropdown " + hamburgerMenuState} ref={menuRef}>
        <div className="header-dropdown_container">
          <div className="hmenu_sec1">
            <p className="hmenu_title">
              <Link
                to="/jobseekers"
                onClick={() => setHamburgerMenuState("")}
                onKeyDown={e => {
                  if (e.key === "Enter" || e.key === " ") {
                    setHamburgerMenuState("")
                  }
                }}
              >
                <img src={arrowRight} alt="arrow-right" />
                お仕事をお探しの方へ
              </Link>
            </p>
            <div className="hmenu_sec1_2col">
              <div>
                <ul className="hmenu_sec1_2col_first">
                  {menuSec1.map((tag, i) => (
                    <li key={"menu_sec1_" + i}>
                      <Link
                        to={tag.link}
                        onClick={() => setHamburgerMenuState("")}
                        onKeyDown={e => {
                          if (e.key === "Enter" || e.key === " ") {
                            setHamburgerMenuState("")
                          }
                        }}
                      >
                        <img src={dot} alt="dot" />
                        {tag.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <ul className="hmenu_sec1_2col_second">
                  <li>
                    <img src={dot} alt="dot" />
                    人気記事TOP5
                  </li>
                  {menuTopFive.map((tag, i) => (
                    <li key={"topfive_" + i}>
                      <Link
                        to={tag.link}
                        onClick={() => setHamburgerMenuState("")}
                        onKeyDown={e => {
                          if (e.key === "Enter" || e.key === " ") {
                            setHamburgerMenuState("")
                          }
                        }}
                      >
                        {tag.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="hmenu_sec2">
            <p className="hmenu_title">
              <img src={arrowRight} alt="arrow-right" />
              メキシコ就職・生活情報
            </p>
            <div>
              <ul className="hmenu_sec1_2col_first">
                {menuSec2.map((tag, i) => (
                  <li key={"menu_sec2_" + i}>
                    <Link
                      to={tag.link}
                      onClick={() => setHamburgerMenuState("")}
                      onKeyDown={e => {
                        if (e.key === "Enter" || e.key === " ") {
                          setHamburgerMenuState("")
                        }
                      }}
                    >
                      <img src={dot} alt="dot" />
                      {tag.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="hmenu_sec3">
            <div>
              <ul>
                {menuSec3.map((tag, i) => (
                  <li key={"menu_sec3_" + i}>
                    <Link
                      to={tag.link}
                      onClick={() => setHamburgerMenuState("")}
                      onKeyDown={e => {
                        if (e.key === "Enter" || e.key === " ") {
                          setHamburgerMenuState("")
                        }
                      }}
                    >
                      {tag.name}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="hmenu_sec3_final">
                <div className="hmenu_sec3_social-flag">
                  <div className="container-social">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/quickglobalmexico/"
                      aria-label="Instagram"
                      onClick={() => setHamburgerMenuState("")}
                    >
                      <FaInstagram />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/quickmexico"
                      aria-label="Twitter"
                      onClick={() => setHamburgerMenuState("")}
                    >
                      <FaXTwitter />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/919mexico/"
                      aria-label="Facebook"
                      onClick={() => setHamburgerMenuState("")}
                    >
                      <FaFacebookF />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/919mexico/"
                      aria-label="Linkedin"
                      onClick={() => setHamburgerMenuState("")}
                    >
                      <FaLinkedinIn />
                    </a>
                  </div>
                  <div className="container-flag">
                    <a
                      href="https://919mexico.com/en"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="usFlag"
                      onClick={() => setHamburgerMenuState("")}
                    >
                      <img src={usFlag} alt="us-flag" />
                    </a>
                    <a
                      href="https://919mexico-ch.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="chFlag"
                      onClick={() => setHamburgerMenuState("")}
                    >
                      <img src={chinaFlag} alt="china-flag" />
                    </a>
                  </div>
                </div>
                <TimeMexJp />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
