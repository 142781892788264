import React, { useContext, useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import { FaSearch, FaCaretDown } from "react-icons/fa"
import { SearchContext } from "../context/SearchContext"
const SearchHeader = () => {
  const { setText, setType, text, type, setDataSearch } =
    useContext(SearchContext)
  const [openBox, setOpenBox] = useState("")

  const handleChangeText = event => {
    setDataSearch([])
    const textSearch = event.target.value
    setText({ text: textSearch })
  }
  const handleChangeType = event => {
    setDataSearch([])
    const textWork = event.target.value
    setType({ type: "jobs" }) //textWork
  }
  const handleSearch = e => {
    e.preventDefault()
    setDataSearch([])
    // const inputSelect = document.querySelector(".input-select").value
    // const inputText = document.querySelector(".input-text").value
    // type = inputSelect
    // if (inputSelect === "DEFAULT" || inputText === "") {
    //   setTimeout(() => {
    //   }, 2000)
    // } else {
    // if (type.type === "jobs") {
    navigate(`/search/?type=jobs&keyword=${text.text}`)
    // }
    // If it is not jobs search by article
    // else {
    //   navigate(`/search/?type=article&keyword=${text.text}`)
    // }
    // }
  }

  const [searchState, setSearchState] = useState(false)

  // REF Search
  const refSearch = useRef(null)
  // When click outside
  useEffect(() => {
    const handleOutSideClickSearch = event => {
      if (!refSearch.current?.contains(event.target)) {
        setSearchState(false)
      }
    }
    window.addEventListener("mousedown", handleOutSideClickSearch)
    return () => {
      window.removeEventListener("mousedown", handleOutSideClickSearch)
    }
  }, [refSearch])

  const SearchBox = () => {
    return (
      <div className="toolbar-search">
        <div className="content-select-arrow">
          <select
            name="selectWork"
            defaultValue={"DEFAULT"}
            onChange={e => handleChangeType(e)}
            onBlur={e => handleChangeType(e)}
            className="input-select"
          >
            <option value="DEFAULT" disabled>
              検索方法
            </option>
            <option value="jobs">求人</option>
            <option value="articles">記事</option>
          </select>
          <FaCaretDown />
        </div>
      </div>
    )
  }

  return (
    <>
      {searchState ? (
        <div className="header-right_searchbox" ref={refSearch}>
          <div className={"header-right_searchbox_form " + openBox}>
            <input
              type="text"
              placeholder="求人を探す"
              className="input-text"
              onChange={e => handleChangeText(e)}
            />
            {/* Search only for jobs for now */}
            {/* {SearchBox()} */}
          </div>
          <FaSearch
            className="faSearch"
            onClick={e => {
              handleSearch(e)
              setSearchState(false)
              setOpenBox("")
            }}
          />
        </div>
      ) : (
        <FaSearch
          className="faSearch"
          onClick={() => {
            searchState ? setSearchState(false) : setSearchState(true)
            searchState ? setOpenBox("") : setOpenBox("open-box")
          }}
        />
      )}
    </>
  )
}

export default SearchHeader
