import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
// Images header Mexico
import logoMexico from "../../images/919_mexico_logo.webp"
import lanSwitcher from "../../images/new-header/lan-switcher.svg"

const HeaderEs = () => {
  //Menu state
  const [hamburgerMenuState, setHamburgerMenuState] = useState("")

  //Click outside menu
  const menuRef = useRef(null)
  const hamburgerButton = useRef(null)

  const handleClickOutsideMenu = event => {
    if (
      !hamburgerButton.current.contains(event.target) &&
      !menuRef.current.contains(event.target)
    ) {
      setHamburgerMenuState("")
    }
  }

  //Menu state
  const [isVisible, setIsVisible] = useState(false)
  //Click outside menu
  const switcherRef = useRef(null)
  const switcherButton = useRef(null)
  const handleClickOutsideSwitcher = event => {
    if (
      !switcherRef.current.contains(event.target) &&
      !switcherButton.current.contains(event.target)
    ) {
      setIsVisible(false)
    }
  }

  const toggleDiv = () => {
    setIsVisible(prev => !prev)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideMenu)
    document.addEventListener("mousedown", handleClickOutsideSwitcher)
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu)
      document.addEventListener("mousedown", handleClickOutsideSwitcher)
    }
  }, [])

  const enMenu = [
    { name: "Ofertas de trabajo", link: "/es/jobs-listing" },
    { name: "Proceso", link: "/es/process" },
    { name: "Sobre Nosotros", link: "/es/about" },
    { name: "Formulario de Consulta", link: "/es/job-form" },
  ]

  return (
    <>
      <div className="header-style">
        <Link to="/es" className="mexico-logo">
          <img src={logoMexico} alt="919 Mexico" width="380" height="40" />
        </Link>
        <div className="header-right header-right-en">
          <Link to="/es/jobs-listing" className="no-show-mobile">
            Ofertas de trabajo
          </Link>
          <Link to="/es/process" className="no-show-mobile">
            Proceso
          </Link>
          <Link to="/es/about" className="no-show-mobile">
            Sobre Nosotros
          </Link>
          <Link to="/es/job-form" className="no-show-mobile pl-10">
            <button className="header-right-en_btn">Consulta Gratuita</button>
          </Link>
          <div className="lang-switcher">
            <div
              className="u-flex u-align-center"
              onClick={toggleDiv}
              ref={switcherButton}
            >
              <img src={lanSwitcher} alt="cambio de lenguaje" />
            </div>
            <div
              className={"lang-dropdown " + `${isVisible ? "show" : ""}`}
              ref={switcherRef}
            >
              <ul>
                <li className="mb-05">
                  <a href="/">JP</a>
                </li>
                <li className="mb-05">
                  <a href="/en">EN</a>
                </li>
                <li>
                  <a
                    href="https://919mexico-ch.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CN
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div
            className={"hamburger-menu " + hamburgerMenuState}
            onClick={() => {
              hamburgerMenuState === ""
                ? setHamburgerMenuState("active nodesk")
                : setHamburgerMenuState("")
            }}
            role="button"
            tabIndex="0"
            onKeyDown={e => {
              if (e.key === "Enter" || e.key === " ") {
                hamburgerMenuState === ""
                  ? setHamburgerMenuState("active nodesk")
                  : setHamburgerMenuState("")
              }
            }}
            ref={hamburgerButton}
            aria-label="Hamburger menu English"
          >
            <svg id="hamburger" width="40" height="30" className="show-mobile">
              <g
                stroke="#000"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path id="top-line" d="M10,10 L35,10 Z"></path>
                <path id="middle-line" d="M10,17 L35,17 Z"></path>
                <path id="bottom-line" d="M10,24 L35,24 Z"></path>
              </g>
            </svg>
          </div>
        </div>
      </div>

      <div className={"header-dropdown " + hamburgerMenuState} ref={menuRef}>
        <div className="header-dropdown_container mob-menu-en">
          {enMenu.map((tag, i) => (
            <ul>
              <li>
                <Link
                  to={tag.link}
                  onClick={() => setHamburgerMenuState("")}
                  onKeyDown={e => {
                    if (e.key === "Enter" || e.key === " ") {
                      setHamburgerMenuState("")
                    }
                  }}
                >
                  {tag.name}
                </Link>
              </li>
              {i !== enMenu.length - 1 && <hr />}
            </ul>
          ))}
        </div>
      </div>
    </>
  )
}

export default HeaderEs
